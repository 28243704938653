import { useState, useEffect } from 'react';
function useScrollDirection() {
  var _useState = useState(null),
    scrollDirection = _useState[0],
    setScrollDirection = _useState[1];
  var _useState2 = useState(false),
    isScrollingDown = _useState2[0],
    setIsScrollingDown = _useState2[1];
  var _useState3 = useState(true),
    isAtTop = _useState3[0],
    setIsAtTop = _useState3[1];
  var _useState4 = useState(false),
    isAtBottom = _useState4[0],
    setIsAtBottom = _useState4[1];
  useEffect(function () {
    var lastScrollY = window.scrollY;
    var updateScrollDirection = function updateScrollDirection() {
      var scrollY = window.scrollY;
      var windowHeight = window.innerHeight;
      var documentHeight = document.documentElement.scrollHeight;
      if (scrollY > lastScrollY) {
        setScrollDirection('down');
        setIsScrollingDown(true);
      } else if (scrollY < lastScrollY) {
        setScrollDirection('up');
        setIsScrollingDown(false);
      }
      setIsAtTop(scrollY === 0);
      setIsAtBottom(scrollY + windowHeight >= documentHeight);
      lastScrollY = scrollY;
    };
    window.addEventListener('scroll', updateScrollDirection);
    return function () {
      return window.removeEventListener('scroll', updateScrollDirection);
    };
  }, []);
  return {
    scrollDirection: scrollDirection,
    isScrollingDown: isScrollingDown,
    isAtTop: isAtTop,
    isAtBottom: isAtBottom
  };
}
export default useScrollDirection;

// Example Usage

// import useScrollDirection from './useScrollDirection';
//
// function ScrollComponent() {
//     const { scrollDirection, isScrollingDown, isAtTop, isAtBottom } = useScrollDirection();
//
//     return (
//         <div>
//             <h1>Scroll Detector</h1>
//     <p>Scroll Direction: {scrollDirection}</p>
//     <p>Scrolling Down: {isScrollingDown ? 'Yes' : 'No'}</p>
//     <p>At Top: {isAtTop ? 'Yes' : 'No'}</p>
//     <p>At Bottom: {isAtBottom ? 'Yes' : 'No'}</p>
//     </div>
// );
// }
//
// export default ScrollComponent;