import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
var EVENT_NAME = "SIDE_MENU_OPEN_CLOSE_EVENT";
var EVENT_NAME_TOGGLE = "EVENT_NAME_TOGGLE";
function dispatchSideMenuOpenClose(isOpen) {
  if (__IS_SSR__) return;
  var event = new CustomEvent(EVENT_NAME, {
    detail: {
      isOpen: isOpen
    }
  });
  window.dispatchEvent(event);
}
function dispatchSideMenuToggle() {
  if (__IS_SSR__) return;
  var event = new CustomEvent(EVENT_NAME_TOGGLE);
  window.dispatchEvent(event);
}
function useSideMenuController() {
  var _useState = useState(false),
    boolVal = _useState[0],
    setBoolVal = _useState[1];
  var location = useLocation();
  useEffect(function () {
    var handleEvent = function handleEvent(event) {
      var customEvent = event;
      setBoolVal(customEvent.detail.isOpen);
    };
    window.addEventListener(EVENT_NAME, handleEvent);

    // Cleanup the event listener
    return function () {
      window.removeEventListener(EVENT_NAME, handleEvent);
    };
  }, []);
  useEffect(function () {
    var handleEvent = function handleEvent() {
      setBoolVal(!boolVal);
    };
    window.addEventListener(EVENT_NAME_TOGGLE, handleEvent);

    // Cleanup the event listener
    return function () {
      window.removeEventListener(EVENT_NAME_TOGGLE, handleEvent);
    };
  }, [boolVal]);
  useEffect(function () {
    // Reset the loading state when location pathname changes
    dispatchSideMenuOpenClose(false);
  }, [location.pathname]);
  return boolVal;
}
export { dispatchSideMenuOpenClose, dispatchSideMenuToggle };
export default useSideMenuController;