import { useEffect, useState } from 'react';
// Default data modifier implementation
function DEFAULT_DATA_MODIFIER(originalData, newData) {
  return newData;
}

/**
 * Custom hook to call an API and handle loading and error states
 * @param params
 * @param apiCall
 * @param dataModifier
 * @returns An object with the loading state, error state, and data returned by the API
 * @example
 *   const [loading, error, data] = useApi<IPostDetails[]>(
 *     [userId], // Dependency array, triggers refetch if userId changes
 *     () => api_getRecommendedPosts(userId), // API function that returns a Promise
 *     (data) => data.filter(post => post.isRecommended) // Data modifier function
 *   );
 *
 *   if (loading) {
 *     return <div>Loading...</div>;
 *   }
 *
 *   if (error) {
 *     return <div>Error: {error.message}</div>;
 *   }
 */
function useApi(params, apiCall) {
  var dataModifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_DATA_MODIFIER;
  var _useState = useState(true),
    loading = _useState[0],
    setLoading = _useState[1];
  var _useState2 = useState(null),
    error = _useState2[0],
    setError = _useState2[1];
  var _useState3 = useState(null),
    data = _useState3[0],
    setData = _useState3[1];
  useEffect(function () {
    var isMounted = true;
    setLoading(true);
    setError(null);
    apiCall().then(function (newData) {
      if (isMounted) {
        var modifiedData = dataModifier(data, newData);
        setData(modifiedData);
        setLoading(false);
      }
    })["catch"](function (err) {
      if (isMounted) {
        setError(err);
        setLoading(false);
      }
    });
    return function () {
      isMounted = false;
    };
  }, params);
  return [loading, error, data];
}
export default useApi;