import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
function dispatchFullLoading(isLoading) {
  if (__IS_SSR__) return;
  var event = new CustomEvent("FULL_LOADING_EVENT", {
    detail: {
      isLoading: isLoading
    }
  });
  window.dispatchEvent(event);
}
function useFullLoading() {
  var _useState = useState(false),
    buttonLoading = _useState[0],
    setButtonLoading = _useState[1];
  var location = useLocation();
  useEffect(function () {
    // Event listener for "FULL_LOADING_EVENT"
    var handleFullLoadingEvent = function handleFullLoadingEvent(event) {
      var customEvent = event;
      setButtonLoading(customEvent.detail.isLoading);
    };
    window.addEventListener("FULL_LOADING_EVENT", handleFullLoadingEvent);

    // Cleanup the event listener
    return function () {
      window.removeEventListener("FULL_LOADING_EVENT", handleFullLoadingEvent);
    };
  }, []);
  useEffect(function () {
    // Reset the loading state when location pathname changes
    dispatchFullLoading(false);
  }, [location.pathname]);
  return buttonLoading;
}
export { dispatchFullLoading };
export default useFullLoading;